import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

//nam5
// const firebaseConfig = {
//   apiKey: "AIzaSyAU_KiVAlI3xivP06EzxXQe3SjxzPZW94o",
//   authDomain: "bkltestpoc.firebaseapp.com",
//   databaseURL: "https://bkltestpoc.firebaseio.com",
//   projectId: "bkltestpoc",
//   storageBucket: "bkltestpoc.appspot.com",
//   messagingSenderId: "360625767519",
//   appId: "1:360625767519:web:bdd4642e18023f6ed747ea",
// };




//Tokyo Development
const firebaseConfig = {
  apiKey: "AIzaSyALAiROX7pAYwTAq4S3S07fdhxnna6Ue_k",
  authDomain: "bkltest-asia-northeast1.firebaseapp.com",
  databaseURL: "https://bkltest-asia-northeast1-default-rtdb.firebaseio.com",
  projectId: "bkltest-asia-northeast1",
  storageBucket: "bkltest-asia-northeast1.appspot.com",
  messagingSenderId: "118497509447",
  appId: "1:118497509447:web:5c6730b45c2d0af7e33162",
  measurementId: "G-KPP4TKEL0C"
};

//Tokyo Production
// const firebaseConfig = {
//   apiKey: "AIzaSyBhIlemzukopQMQ2ZqZx1XALqdtW5Tn-2o",
//   authDomain: "bklproduction-asia-northeast1.firebaseapp.com",
//   projectId: "bklproduction-asia-northeast1",
//   storageBucket: "bklproduction-asia-northeast1.appspot.com",
//   messagingSenderId: "602749160270",
//   appId: "1:602749160270:web:dfc6a3a49e9084a4d52c1f",
//   measurementId: "G-K9HVCXEV0H"
// };
console.log("App.length:", firebase.apps.length);
if (!firebase.apps.length) {
  console.log("Step1");
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
let functionsTemp = null;


if (process.env.NODE_ENV === "development") {
  console.log('development mode');
  functionsTemp = firebase.functions();
  functionsTemp.useEmulator('localhost', 25001);
  firestore.useEmulator('localhost', 28080);
} else {
  console.log("Step2");
  console.log('production mode');
  functionsTemp = firebase.app().functions("asia-northeast1");
  // functionsTemp = firebase.app().functions();
  console.log("Step3");
}

const functions = functionsTemp;

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

//
// functions.useEmulator('localhost', 25001);
// firestore.useEmulator('localhost', 28080);
//


export {firestore, functions, timestamp};
